import {
  StyleSheet,
  Text,
  View,
  Dimensions,
  TouchableOpacity,
} from "react-native";
import CustomButton from "../../components/CustomButton";
import Calendly from "../../components/Calendly";
import { PoppinsBoldText } from "../../components/PopinsText";
import Title from '../../components/Title';
import CustomCheckBox from '../../components/CustomCheckBox';
import { useState } from "react";
import { firsStepInitialValue } from "@podcasts/store/application";
import { useSelector } from "react-redux";


export default function FourthStep({ onNext, onPrev }) {
  const initialValues = useSelector(firsStepInitialValue);
  const screenWidth = Dimensions.get('window').width;

  const [checkBox, setCheckBox] = useState(false);
  const prefillValue = {
    name: `${initialValues.fname} ${initialValues.lname}`,
    email: initialValues.email,
    phone: initialValues.phone,
    a1: initialValues.phone,
  };
  const onScheduled = () => {
    console.log('scheduled');
    setTimeout(() => onNext(), 3000);
  }
  return (
      <View style={styles.login}>
        <View style={styles.step_number}>
          <Text style={styles.step_number_text}>4</Text>
        </View>
        <Title style={{ marginBottom: 30, height: 165, maxWidth: screenWidth - 40 }} source={ require('../../assets/titles/application_process_4.jpg')}/>

        {/* <PoppinsBoldText style={styles.step_title}>
        Schedule an intake appointment to
        </PoppinsBoldText>
         <PoppinsBoldText style={styles.pf_title}>
         complete your application.
        </PoppinsBoldText> */}

        <View style={styles.form}>
          <Text style={{ marginBottom: 20,textAlign: 'center',fontFamily: 'SofiaPro', fontSize: 13}}>Please select a time to meet. We look forward to getting to know you and guiding you through the program!</Text>
          <Calendly dob={initialValues.dob} prefillValue={prefillValue} onScheduled={onScheduled}/>
          <View style={{ marginTop: 20}}>
          {/* <CustomCheckBox 
                    onChange={(value) => { setCheckBox(value) } }
                    onBlur={() => { }}
                    value={checkBox}
                    labelColor="black"
                    label="I have selected a timeslot for my intake interview from the calendar above."
                /> */}
          </View>
          {/* <View style={styles.next_step}>
            <CustomButton
              decorator
              name={"Next step"}
              color="blue"
              onPress={() => {
                onNext();
              }}
            />
          </View> */}
{/* 
          <View style={styles.prev_step}>
            <TouchableOpacity
              onPress={() => {
                onPrev();
              }}
            >
              <Text style={styles.prev_step_text}>Previous step</Text>
            </TouchableOpacity>
          </View> */}
        </View>
      </View>
  );
}

const styles = StyleSheet.create({
  login: {
    flex: 1,
    width: '100%',
    alignItems: "center",
    justifyContent: "flex-start",
    alignSelf: "center",
    paddingTop: 40,
    paddingBottom: 50,
  },
  step_number: {
    width: 56,
    height: 56,
    justifyContent: "center",
    alignItems: "center",
    borderRadius: 50,
    borderWidth: 1,
    borderColor: "#000",
  },
  step_number_text: {
    fontSize: 18,
    color: "#000",
    fontWeight: "normal",
  },
  step_title: {
    fontWeight: "bold",
    fontSize: 26,
    color: "#021C3C",
    textAlign: "center",
    lineHeight: 39,
    marginTop: 30,
  },
  step_subtitle: {
    fontWeight: "normal",
    fontSize: 16,
    color: "#000",
    textAlign: "center",
    lineHeight: 26,
    marginTop: 15,
  },
  form: {
    width: '100%',
  },
  form_control: {
    width: "100%",
    marginTop: 20,
  },
  next_step: {
    marginTop: 40,
  },
  prev_step: {
    marginTop: 40,
  },
  prev_step_text: {
    textAlign: "center",
    fontSize: 18,
    lineHeight: 18,
    fontWeight: "500"
  },
  pf_title: {
    color: '#FCB912',
    fontSize: 26,
    marginBottom: 22,
    textAlign: 'center',
    marginBottom: 20,
  },
});
