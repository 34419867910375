import {
  StyleSheet,
  ScrollView,
  Text,
  View,
  Image,
  Pressable,
  Platform,
  Alert
} from "react-native";
import MenuItem from "../../components/MenuItem";
import Icon from "../../components/icons/CustomIcon";
import { userSelector } from "@podcasts/store/user";
import { useSelector } from "react-redux";
import { useLinkTo } from "@react-navigation/native";
import { deleteProfile } from '@podcasts/api';
import { logOut } from "@podcasts/store/user";
import { useDispatch } from "react-redux";
import { useNavigation } from "@react-navigation/native";

const ProfileMenu = [
  {
    icon: "payment-dark",
    sizeIcon: 21,
    name: "Your payments",
    page: "PaymentsPage",
  },
  {
    icon: "payment-dark",
    sizeIcon: 21,
    name: "Referrals",
    page: "ReferralsPage",
  },
  {
    icon: "note-dark",
    sizeIcon: 20,
    name: "Recent notes",
    page: "RecentNotesPage",
  },
  {
    icon: "user-plus",
    sizeIcon: 18,
    name: "Refer a friend",
    page: "ReferFriendPage",
  },
];

export default function StartPage() {
  const { user } = useSelector(userSelector);
  const linkTo = useLinkTo();
  const dispatch = useDispatch();
  const navigation = useNavigation();

  const showConfirmDialog = () => {
    return Alert.alert(
      "Are your sure?",
      "Are you sure you want to delete profile",
      [
        {
          text: "Yes",
          onPress: async () => {

            await  deleteProfile().then(() => {
                dispatch(logOut());
                navigation.reset({
                  index: 0,
                  routes: [{ name: Platform.OS === 'web'? 'Dashboard' : 'GetStarted'}]
                })
            });
          },
        },
        {
          text: "No",
        },
      ]
    );
  };


  return (
    <ScrollView>
      <View style={styles.profile_info}>
        <View style={styles.profile_avatar}>
          <Image source={{ uri: user?.image }} style={styles.user_photo} />
          <View style={styles.img_after}></View>
          <Pressable style={styles.img_edit}>
            <Icon name="edit-light" size={15} color="#fff" />
          </Pressable>
        </View>
        <Text style={styles.user_name}>Hello, {user?.fname}.</Text>
        {/* <View style={{ width: 210 }}>
          <DropDown
            label="Select Item"
            value={selectedLevel}
            options={levelOptions}
            onSelect={({ value }) => {
              onLevelChange(value);
            }}
          />
        </View> */}
        <View style={styles.info_item}>
          <Icon name="mail" size={12} color="#000" />
          <Text style={styles.info_item_text}>{user?.email}</Text>
        </View>
        <View style={styles.info_item}>
          <Icon name="pin" size={16} color="#000" />
          <Text style={styles.info_item_text}>{user?.city}</Text>
        </View>
        <View style={styles.info_item}>
          <Icon name="phone" size={16} color="#000" />
          <Text style={styles.info_item_text}>{user?.phone}</Text>
        </View>
        <View style={styles.info_item}>
          <Icon name="university" size={15} color="#000" />
          <Text style={styles.info_item_text}>{user?.school}</Text>
        </View>
        <View>
          <Pressable
            style={styles.edit_button}
            onPress={() => {
              linkTo("/profile/edit");
            }}
          >
            <Text style={styles.edit_button_text}>Edit profile</Text>
          </Pressable>
        </View>
      </View>
      <View>
        <View style={styles.profile_menu}>
          {ProfileMenu.map((item, i) => (
            <MenuItem
              onPress={() => {
                navigation.push(item.page);
              }}
              key={i}
              {...item}
            />
          ))}
          { Platform.OS !== 'web' &&
           <MenuItem
              onPress={showConfirmDialog}
              key={'delete'}
              { ...{  sizeIcon: 21, name: "Delete profile"}}
            />}
        </View>
      </View>
    </ScrollView>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    width: "100%",
    maxWidth: 600,
    alignSelf: "center",
    paddingHorizontal: 20,
    paddingTop: 35,
  },
  bottom_nav: {
    padding: 20,
  },
  bottom_nav_link: {
    fontWeight: `500`,
    fontSize: 14,
    lineheight: 14,
    marginBottom: 20,
  },
  profile_menu: {
    marginTop: 20,
    paddingHorizontal: 20
  },
  profile_info: {
    alignItems: "center",
    paddingTop: 23,
    paddingBottom: 15,
    borderBottomColor: "#D8D8D8",
    borderBottomWidth: 1,
  },
  profile_avatar: {
    width: 94,
    marginBottom: 20,
  },
  user_photo: {
    width: 94,
    height: 94,
    borderRadius: 47,
    borderColor: "#000",
    borderWidth: 1,
    backgroundColor: "white",
  },
  img_after: {
    width: 94,
    height: 94,
    borderRadius: 47,
    backgroundColor: "#FCB912",
    position: "absolute",
    left: 6,
    top: 5,
    zIndex: -1,
  },
  img_edit: {
    width: 30,
    height: 30,
    borderRadius: 15,
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: "#F51D00",
    position: "absolute",
    right: -5,
    top: 0,
  },
  user_name: {
    color: "#021C3C",
    fontSize: 20,
    lineHeight: 27,
    fontWeight: "bold",
    marginBottom: 15,
    fontFamily: 'Poppins_700Bold'
  },
  user_rating: {
    marginBottom: 25,
    alignItems: "center",
  },
  info_item: {
    flexDirection: "row",
    alignItems: "center",
    marginBottom: 10,
  },
  info_item_text: {
    color: "#000",
    fontSize: 15,
    lineHeight: 25,
    fontWeight: "normal",
    marginLeft: 11,
    fontFamily: 'SofiaPro'
  },
  player: {
    position: "absolute",
    width: "100%",
    left: 0,
    bottom: 0,
    zIndex: 2,
  },
  edit_button: {
    width: 116,
    height: 36,
    borderRadius: 4,
    borderWidth: 1,
    borderColor: "#042B59",
    justifyContent: "center",
    alignItems: "center",
  },
  edit_button_text: {
    fontSize: 15,
    fontWeight: "400",
    color: "#042B59",
    fontFamily: 'SofiaPro'
  },
});
