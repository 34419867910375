import { View, Platform, ActivityIndicator } from "react-native";
import { WebView } from "react-native-webview";
import moment from "moment";
import qs from "qs";
import { useCallback } from "react";
import { useFocusEffect } from "@react-navigation/native";

const CALENDLY_URL="https://calendly.com/podcastfellow/interview"
const CALENDLY_32_PLUS_URL="https://calendly.com/dhaas-1-1-learning/15min?month=2023-07"
function isCalendlyEvent(e) {
  return e.data.event &&
         e.data.event.indexOf('calendly') === 0;
};

export default function CalendlyComponent({ dob, prefillValue = {}, onScheduled }) {
  var born = moment(dob);
  var today = moment(new Date());

  const is32Plus = today.diff(born, 'years') >= 32;
  const queryParam = qs.stringify(prefillValue);
  const src = is32Plus ? `${CALENDLY_32_PLUS_URL}&${queryParam}` : `${CALENDLY_URL}?${queryParam}`;
  const initCalendly = useCallback(() => {
    Calendly.initInlineWidget({
      url: src,
      parentElement: document.getElementById('calendlyIframe'),
      prefill: prefillValue,
      utm: {}
     });
  }, [src, prefillValue]);
  
  if (Platform.OS === "web") {
    useFocusEffect(() => {
        const script = document.createElement("script");
        script.src = "https://assets.calendly.com/assets/external/widget.js";
        script.id = "calendlyScript";
        script.async = true;
        script.onload = () => {
          initCalendly();
        }
        document.body.appendChild(script);
        window.addEventListener("message", function(e) {
          if(isCalendlyEvent(e)) {
            console.log("Event name:", e.data.event);
            console.log("Event details:", e.data.payload);
            if (this.event.data.event === 'calendly.event_scheduled') {
              onScheduled(); 
            } else if (this.event.data.event === 'calendly.event_type_viewed') {
              const loading = document.getElementById("loading");
              loading.remove();
            }
          }
      });

      return () => {
        window.removeEventListener("message", () => {});
        const calendlyScript = document.getElementById("calendlyScript");
        if (calendlyScript) {
          calendlyScript.remove();
        }
        const calendlyIframe = document.getElementById("calendlyIframe");
        if (calendlyIframe) {
          calendlyIframe.innerHTML = "";
        }
      }
    });
    return (
      <View style={{ width: "100%" }}>
        {/* <iframe src={src} frameBorder="0" height="820px" scrolling="no"/> */}
        <View style={{ width: "100%", height: 1200 }} id="calendlyIframe">
          <View
            id="loading"
            style={{
              backgroundColor: "#ffffff9e",
              position: "absolute",
              zIndex: 11,
              width: "100%",
              height: "100%",
              display: "flex",
              justifyContent: "center",
            }}
          >
            <ActivityIndicator size="large" color="#fcb912" />
          </View>
        </View>
      </View>
    );
  } else {
    const injectedJavaScript = `
      (function() {
        console.log("Injecting Calendly widget script");
        var script = document.createElement('script');
        script.src = 'https://assets.calendly.com/assets/external/widget.js';
        script.async = true;
        script.onload = function() {
          console.log("Calendly widget script loaded");
          Calendly.initInlineWidget({
            url: '${src}',
            parentElement: document.getElementById('calendly-container'),
            prefill: ${JSON.stringify(prefillValue)},
            utm: {}
          });

          window.addEventListener('message', function(event) {
            console.log("Message received:", event.data);
            if (event.data.event) {
              window.ReactNativeWebView.postMessage(JSON.stringify(event.data));
            }
          });
        };
        document.body.innerHTML = '<div id="calendly-container" style="height:1200px"></div>';
        document.head.appendChild(script);
      })();
true;`;
  const handleMessage = (event) => {
    const { data } = event.nativeEvent;

    try {
    const parsedData = JSON.parse(data);
    console.log("Received Calendly event data:", parsedData);
    if (parsedData.event === "calendly.event_scheduled") {
      console.log('onScheduled');
      onScheduled();
    } else {
      // console.log("Received Calendly event data:", parsedData);
    }
    
    } catch (error) {
      console.error("Error parsing message from WebView:", error);
    }
  };
  
    return (
      <WebView
        originWhitelist={["*"]}
        javaScriptEnabled={true}
        style={{ height: 1200, width: "100%" }}
        source={{ uri: src }}
        injectedJavaScript={injectedJavaScript}
        onMessage={handleMessage}
        startInLoadingState={true}
        renderLoading={() => <View
          id="loading"
          style={{
            backgroundColor: "#ffffff9e",
            position: "absolute",
            zIndex: 11,
            width: "100%",
            height: "100%",
            display: "flex",
            justifyContent: "center",
          }}
        >
          <ActivityIndicator size="large" color="#fcb912" />
        </View>}
      />
    );
  }
}
