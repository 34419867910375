import { ScrollView, SafeAreaView } from "react-native";
import { useDispatch, useSelector } from "react-redux";
import {
  setFirstStep,
  setSecondStep,
  setThirdStep,
} from "@podcasts/store/application";
import FirstStep from "./FirstStep";
import SecondStep from "./SecondStep";
import ThirdStep from "./ThirdStep";
import FourthStep from "./FourthStep";
import { appSelector, prevStep, nextStep } from "@podcasts/store/application";
import Success from "./Success";
import { KeyboardAwareScrollView } from 'react-native-keyboard-aware-scroll-view'
import { useEffect, useRef } from 'react';
import TabsHeader from "../../components/TabsHeader";

function ApplicationProcessBase() {
  const dispatch = useDispatch();
  const { step, isBornJewish, error } = useSelector(appSelector);
  const scrollRef = useRef(null)

  const commonProp = {
    onPrev: () => {
      dispatch(prevStep());
    },
  };

  useEffect(() => {
    if([2,3,4].includes(step)) {
      // window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
      scrollRef.current.scrollToPosition(0, 0)
    }
  },[step]);

  const renderSteps = (n) => {
    switch (n) {
      case 1:
        return (
          <FirstStep
            {...commonProp}
            onNext={(values) => {
              dispatch(setFirstStep(values));
            }}
          />
        );
      case 2:
        return (
          <SecondStep
            {...commonProp}
            onPrev={() => {
              dispatch(prevStep());
            }}
            onNext={(values) => {
              dispatch(setSecondStep(values));
            }}
          />
        );
      case 3:
        return (
          <ThirdStep
            {...commonProp}
            onNext={(values) => {
              dispatch(setThirdStep(values));
            }}
          />
        );
      case 4:
        return (
          <FourthStep
            {...commonProp}
            onNext={() => {
              dispatch(nextStep());
            }}
          />
        );
      case 5:
        return <Success isBornJewish={isBornJewish}/>;
    }
  };

  return <KeyboardAwareScrollView  extraScrollHeight={30} ref={scrollRef} style={{ paddingHorizontal: 20}}>{renderSteps(step)}</KeyboardAwareScrollView>;
}
export const NativeApplicationProcess = (props) => {
  return (
    <SafeAreaView style={{ flex: 1 }}>
      {/* <TabsHeader/> */}
      <ApplicationProcessBase {...props} />
    </SafeAreaView>
  );
};

export const WebApplicationProcess = (props) => {
  return (
    <SafeAreaView >
      {/* <TabsHeader/> */}
      <ApplicationProcessBase {...props} />
    </SafeAreaView>
  );
};
