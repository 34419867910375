import { useState, useCallback, useEffect } from 'react';
import { StyleSheet, Text, View, Image, Platform, Modal, Linking, Alert, Pressable } from "react-native";
import { Formik } from "formik";
import CustomButton from "../../components/CustomButton";
import CustomInput from "../../components/CustomInput";
import DatePicker from "../../components/DatePicker";
import { useSelector } from "react-redux";
import { firsStepInitialValue, appSelector } from "@podcasts/store/application";
import CustomSelect from "../../components/CustomSelect";
import { firstApplicationStepSchema } from "@podcasts/schemas";
import { countriesList, genderSelectOptions } from "@podcasts/constants";
import Title from "../../components/Title";
import SvgIcon from "../../components/svg/icon";

const countries = countriesList.map((v) => ({
  value: v.isoCode,
  label: v.name,
}));

export default function FirstStep({ onNext }) {
  const data = useSelector(firsStepInitialValue);

  const { error: registerErrorMessage } = useSelector(appSelector);

  const [modalVisible, setModalVisible] = useState(false);


  const openUrlMailTo = useCallback(async () => {
    // Checking if the link is supported for links with custom URL scheme.
    const supported = await Linking.canOpenURL('mailto:leah@podcastfellowship.org');

    if (supported) {
      // Opening the link with some app, if the URL scheme is "http" the web link should be opened
      // by some browser in the mobile
      await Linking.openURL('mailto:leah@podcastfellowship.org');
    } else {
      Alert.alert(`Don't know how to open this URL: ${url}`);
    }
  }, []);

  useEffect(() => {
    if (registerErrorMessage && registerErrorMessage === "Email is already taken.") {
      setModalVisible(true);
    }
  }, [registerErrorMessage]);

  return (
    <View style={styles.login}>
      <View style={styles.step_number}>
        <Text style={styles.step_number_text}>1</Text>
      </View>
      <Title
        source={require("../../assets/titles/application_process_1.jpg")}
      />
      {/* <PoppinsBoldText style={styles.step_title}>
          Application for
        </PoppinsBoldText>
         <PoppinsBoldText style={styles.pf_title}>
           Podcast Fellowship
        </PoppinsBoldText> */}
      <Text style={styles.step_subtitle}>
        All are welcome to apply to all parts of the Podcast Fellowship, however
        the $15 stipend per podcast comes from a private foundation which only
        pays a stipend for Jewish people to encourage them to understand Judaism
        better.
      </Text>
      <Formik
        enableReinitialize
        initialValues={{ ...data, accept: true }}
        validationSchema={firstApplicationStepSchema(Platform.OS === "ios")}
        onSubmit={(values) => {
          onNext(values);
        }}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
          setFieldValue,
          isSubmitting,
          /* and other goodies */
        }) => (
          <View style={styles.form}>
            <View style={styles.form_control}>
              <CustomInput
                onChangeText={handleChange("fname")}
                onBlur={handleBlur("fname")}
                value={values.fname}
                error={errors.fname && touched.fname ? errors.fname : null}
                label={"First Name *"}
              />
            </View>

            <View style={styles.form_control}>
              <CustomInput
                onChangeText={handleChange("lname")}
                onBlur={handleBlur("lname")}
                value={values.lname}
                error={errors.lname && touched.lname ? errors.lname : null}
                label={"Last Name *"}
              />
            </View>

            <View style={styles.form_control}>
              <CustomInput
                autoCapitalize="none"
                keyboardType={"email-address"}
                onChangeText={handleChange("email")}
                onBlur={handleBlur("email")}
                value={values.email}
                error={errors.email && touched.email ? errors.email : null}
                label={"Email *"}
              />
            </View>
            <View style={styles.form_control}>
              <CustomSelect
                options={genderSelectOptions}
                onChange={(v) => {
                  setFieldValue("gender", v || null);
                }}
                onBlur={handleBlur("gender")}
                value={values.gender}
                error={errors.gender && touched.gender ? errors.gender : null}
                label={"Gender"}
                placeholder={{
                  label: "Please select:",
                  color: "#7E7E7E",
                  fontSize: 15,
                }}
              />
            </View>
            <View style={styles.form_control}>
              <CustomInput
                keyboardType="phone-pad"
                onChangeText={handleChange("phone")}
                onBlur={handleBlur("phone")}
                value={values.phone}
                error={errors.phone && touched.phone ? errors.phone : null}
                label={`Phone Number ${Platform.OS === "ios" ? "" : "*"}`}
                placeholder={"( _ _ _ )  _ _ _   _ _ _ _"}
              />
            </View>

            <View style={[styles.form_control, { zIndex: 33 }]}>
              <DatePicker
                onChange={(value) => {
                  setFieldValue("dob", value);
                }}
                keyboardType="phone-pad"
                onBlur={handleBlur("dob")}
                value={values.dob}
                error={errors.dob && touched.dob ? errors.dob : null}
                label={`Date of Birth ${Platform.OS === "ios" ? "" : "*"}`}
                maxDate={new Date()}
                minDate={new Date("1975-01-01")}
                placeholder={"mm / dd / yyyy"}
              />
            </View>
            <View style={styles.form_control}>
              <CustomSelect
                options={countries}
                onChange={(v) => {
                  setFieldValue("country", v || null);
                }}
                onBlur={handleBlur("country")}
                value={values.country}
                error={
                  errors.country && touched.country ? errors.country : null
                }
                label={"Country (Current Residence) *"}
                placeholder={{
                  label: "Please select:",
                  color: "#7E7E7E",
                  fontSize: 15,
                }}
              />
            </View>

            <View style={styles.form_control}>
              <CustomInput
                onChangeText={handleChange("city_residence")}
                onBlur={handleBlur("city_residence")}
                value={values.city_residence}
                label={"Hometown (if different than current residence)"}
              />
            </View>

            <View style={styles.form_control}>
              <CustomInput
                onChangeText={handleChange("state_residence")}
                onBlur={handleBlur("state_residence")}
                value={values.state_residence}
                label={"Home state (if different than current residence)"}
              />
            </View>
            <View style={styles.next_step}>
              <CustomButton
                decorator
                name={"Next step"}
                color="blue"
                onPress={handleSubmit}
              />
            </View>
          </View>
        )}
      </Formik>
      <Modal
        animationType="slide"
        transparent={true}
        visible={modalVisible}
        onRequestClose={() => {
          Alert.alert('Modal has been closed.');
          setModalVisible(!modalVisible);
        }}>
        <View style={styles.centeredView}>
          <View style={styles.modalView}>
            <View><SvgIcon name={"Notice"} width="40" height="40"/></View>
            <Text style={styles.modalText}>It looks like you've  applied before.</Text>
            <Text style={styles.modalText}>If there's a reason why you are submitting another application, or if you are receiving this message in error, please reach out to <Pressable style={styles.mailto} onPress={openUrlMailTo}><Text>leah@podcastfellowship.org</Text></Pressable></Text>
            <View style={styles.modalButton}>
              <CustomButton
                  decorator
                  name={"Ok"}
                  color="blue"
                  onPress={() => setModalVisible(!modalVisible)}
                />
              </View>
          </View>
        </View>
      </Modal>
    </View>
  );
}

const styles = StyleSheet.create({
  login: {
    flex: 1,
    alignItems: "center",
    justifyContent: "flex-start",
    width: "100%",
    alignSelf: "center",
    paddingTop: 40,
    paddingBottom: 50,
  },
  step_number: {
    width: 56,
    height: 56,
    justifyContent: "center",
    alignItems: "center",
    borderRadius: 28,
    borderWidth: 1,
    borderColor: "#000",
    flexGrow: 1,
    flexShrink: 0,
    flexBasis: 56,
  },
  step_number_text: {
    fontSize: 18,
    color: "#000",
    fontWeight: "normal",
  },
  step_title: {
    fontSize: 26,
    color: "#021C3C",
    textAlign: "center",
    lineHeight: 39,
    marginTop: 30,
  },
  pf_title: {
    color: "#FCB912",
    fontSize: 26,
  },
  step_subtitle: {
    fontWeight: "normal",
    fontSize: 16,
    color: "#000",
    fontFamily: "SofiaPro",
    textAlign: "center",
    lineHeight: 26,
    marginTop: 15,
  },
  form: {
    width: "100%",
  },
  form_control: {
    width: "100%",
    marginTop: 20,
  },
  next_step: {
    marginTop: 40,
  },
  centeredView: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
    marginTop: 22,
  },
  modalView: {
    margin: 20,
    backgroundColor: 'white',
    borderRadius: 20,
    padding: 35,
    alignItems: 'center',
    shadowColor: '#000',
    shadowOffset: {
      width: 0,
      height: 2,
    },
    shadowOpacity: 0.25,
    shadowRadius: 4,
    elevation: 5,
    width: "94%"
  },
  modalButton: {
    marginTop: 20,
    display:  'flex',
    flexDirection: 'column',
    width: "100%"
  },
  modalText: {
    textAlign: 'center'
  },
  mailto: {
    textDecorationLine: 'underline',
    backgroundColor: 'white',
    color: 'black'
  }
});
