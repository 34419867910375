import { useState, useRef } from "react";
import { StyleSheet, Text, View, Pressable, Modal, TouchableOpacity } from "react-native";
import Icon2 from "../icons/CustomIcon2";
import DateTimePicker from 'react-native-ui-datepicker';
import dayjs from 'dayjs';
// import InputMask from "react-input-mask";

export default function CustomInput({
  label,
  value,
  onChange,
  error,
  onBlur,
  placeholder = "",
  ...rest
}) {
  const [showPopover, setShowPopover] = useState(false);
  const touchable = useRef();

  const onChangeValue = (params) => {
    onChange(params.date);
    setShowPopover(false);
  };
  const hideDatePicker = () => {
    setShowPopover(false);
    console.log(touchable.current);
  };
  const showDatePicker = () => {
    setShowPopover(true);
  };
  return (
    <View style={styles.custominput}>
      {label && <Text style={styles.text_name}>{label}</Text>}
      <View>
        {/* <View style={{ position: 'relative'}}>
        <Pressable onPress={showDatePicker} style={{...styles.input, borderColor: error ? "red" : "black",}}>
          <Text style={{ color: value ? 'black' : '#7E7E7E' }}>{value ? moment(value).format("DD / MM / YY") : "mm / dd / yyyy"}</Text>
        </Pressable>
        </View> */}
        <View style={{ position: 'relative'}}>
            <Pressable onPress={showDatePicker}  style={{...styles.input, borderColor: error ? "red" : "black",}}>
              <Text style={{ color: value ? 'black' : '#7E7E7E' }}>{value ? dayjs(value).format("MM / DD / YYYY") : "mm / dd / yyyy"}</Text>
              <Icon2
              name="chevron-select"
              size={11}
              color="#000"
              style={{
                backgroundColor: "white",
                top: 17,
                right: 10,
                position: "absolute",
              }}
            />
            </Pressable>
          </View>
        <Modal
            visible={showPopover}
            onRequestClose={hideDatePicker}
            ref={touchable}
            collapsable={false}
            transparent={true}
              // popoverStyle={{ backgroundColor: 'transparent', padding: 0 }}
        >
          {showPopover  && 
          <TouchableOpacity style={{flex: 1, backgroundColor: 'rgba(0,0,0,0.5)', justifyContent: 'center'}} onPress={hideDatePicker}>
          <View style={styles.centeredView}>
            <DateTimePicker
              mode="single"
              date={value ? value : dayjs()}
              height={340}
              onChange={onChangeValue}
              calendarTextStyle={{ fontFamily: 'SofiaProMedium', fontSize: 20 }}
              selectedItemColor='#042B59'
              headerTextStyle={{ fontFamily: 'SofiaProMedium', fontSize: 20, lineHeight: 32 }}
              weekDaysTextStyle={{ fontFamily: 'SofiaProMedium', fontSize: 20, lineHeight: 24, color: '#C4C4C4' }}
              weekDaysContainerStyle={{ border: 'none' }}
              dayContainerStyle={{backgroundColor: '#F5F5F5', borderRadius: 8, margin: 4}}
              {...rest}
            />
          </View>
          </TouchableOpacity>}
        </Modal>

        <View style={styles.shadow}></View>
      </View>
      {error && <Text style={styles.text_error}>{error}</Text>}
    </View>
  );
}

const styles = StyleSheet.create({
  custominput: {
    width: "100%",
    margin: 0,
  },
  textarea: {
    borderRadius: 10,
    borderWidth: 1,
    borderColor: "#000000",
    paddingHorizontal: 20,
    paddingVertical: 10,
    backgroundColor: "#fff",
    marginBottom: 4,
    textAlignVertical: "top",
  },

  input: {
    borderRadius: 10,
    borderWidth: 1,
    borderColor: "#000000",
    height: 45,
    paddingHorizontal: 20,
    paddingLeft: 20,
    paddingRight: 20,
    backgroundColor: "#fff",
    marginBottom: 4,
    borderStyle: 'solid',
    justifyContent: "center",
  },

  shadow: {
    position: "absolute",
    width: "90%",
    height: 20,
    backgroundColor: "#F9BF34",
    alignSelf: "center",
    borderRadius: 10,
    position: "absolute",
    left: "5%",
    bottom: 0,
    zIndex: -1,
  },

  text_name: {
    marginBottom: 5,
    fontSize: 13,
    lineHeight: 23,
    fontWeight: "normal",
    fontFamily: "SofiaPro",
  },
  text_error: {
    fontSize: 12,
    lineHeight: 12,
    fontWeight: "normal",
    color: "red",
    marginTop: 5,
    marginLeft: '5%',
  },
  centeredView: {
    // flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
    alignSelf: 'center',
    width: 424,
    borderRadius: 16,
    backgroundColor: '#fff',
    padding: 20,
    margin: 20,
    maxWidth: 'calc(100vw - 40px)',
    minHeight: 340
  }
});
